<template>
  <div>
    <Card>
      <p slot="title">通过手机号查询基本信息 / 绑定手机号</p>
      <Form
        :model="formData"
        :rules="validateForm"
        inline
        ref="phoneForm"
        label-colon
        :label-width="100"
        class="flexForm"
      >
        <FormItem label="手机号" prop="phone">
          <Input
            placeholder="请输入手机号码"
            v-model="formData.phone"
            search
            enter-button="查询"
            @on-search="searchInfo"
          ></Input>
        </FormItem>
      </Form>
    </Card>
    <Card>
      <p slot="title">基本信息</p>
      <Form
        :model="formData"
        inline
        label-colon
        :label-width="100"
        class="flexForm"
      >
        <FormItem>
          <span slot="label" class="validate">姓名:</span>
          <Input v-model.trim="formData.name"></Input>
        </FormItem>
        <FormItem label="性别">
          <RadioGroup v-model="formData.sex">
            <Radio label="1">男</Radio>
            <Radio label="2">女</Radio>
          </RadioGroup>
        </FormItem>
        <FormItem label="出生日期">
          <DatePicker
            type="date"
            format="yyyy-MM-dd"
            :disabled="true"
            v-model="formData.birthday"
            placeholder="根据身份证号码自动计算"
          ></DatePicker>
        </FormItem>
        <FormItem>
           <span slot="label" class="validate">身份证号:</span>
          <Input v-model.trim="formData.idNum" @on-change="changeIdNum"></Input>
        </FormItem>
        <FormItem label="年龄">
          <Input
            v-model.trim="formData.age"
            style="width: 200px"
            :disabled="true"
            placeholder="根据身份证号码自动计算"
          ></Input>
        </FormItem>
        <FormItem label="区/街道/社区">
          <Cascader
            :data="communityList"
            :load-data="loadCommunity"
            v-model="formData.userOrgCode"
          ></Cascader>
        </FormItem>
      </Form>
    </Card>
    <Card>
      <Form
        :model="formData"
        inline
        label-colon
        :label-width="100"
        class="flexForm"
      >
        <FormItem label="评估类别">
          <Select v-model="formData.assessType" style="width: 200px">
            <Option value="1">动态评估</Option>
          </Select>
        </FormItem>
        <FormItem label="评估时间">
          <span slot="label" class="validate">评估时间:</span>
          <DatePicker
            type="date"
            v-model="formData.assessDate"
            placeholder="请选择评估时间"
            style="width: 200px"
          ></DatePicker>
        </FormItem>
        <FormItem label="上次评估时间">
          <DatePicker
            type="date"
            v-model="formData.assessDateLast"
            placeholder="请选择上次评估时间"
            style="width: 200px"
          ></DatePicker>
        </FormItem>
      </Form>
    </Card>
    <Card>
      <p slot="title">主要参数评分</p>
      <Form :model="formData" label-colon :label-width="100">
        <FormItem label="老人能力">
          <RadioGroup v-model="formData.elderlyAbility">
            <Radio
              :label="item.dictKey"
              v-for="(item, index) in elderlyAbilityList"
              :key="index"
              >{{ item.dictValue }}</Radio
            >
          </RadioGroup>
        </FormItem>
        <FormItem label="医护照料">
          <RadioGroup v-model="formData.medicalCare">
            <Radio
              :label="item.dictKey"
              v-for="(item, index) in medicalCareList"
              :key="index"
              >{{ item.dictValue }}</Radio
            >
          </RadioGroup>
        </FormItem>
        <FormItem label="疾病状况">
          <RadioGroup v-model="formData.diseaseStatus">
            <Radio
              :label="item.dictKey"
              v-for="(item, index) in diseaseStatusList"
              :key="index"
              >{{ item.dictValue }}</Radio
            >
          </RadioGroup>
        </FormItem>
      </Form>
    </Card>
    <Card>
      <Form
        :model="formData"
        label-colon
        :label-width="100"
        class="flexForm"
        style="margin: 0 20px"
      >
        <FormItem label="社会支持等级" style="width: 100%">
          <RadioGroup v-model="formData.supportLevel">
            <Radio label="1">1级（低水平）</Radio>
            <Radio label="2">2级（中等水平）</Radio>
            <Radio label="3">3级（高水平）</Radio>
          </RadioGroup>
        </FormItem>
        <FormItem label="老人照顾需求" style="width: 100%">
          <RadioGroup v-model="formData.elderlyCareLevel">
            <Radio
              :label="item.dictKey"
              v-for="(item, index) in elderlyCareLevelList"
              :key="index"
              >{{ item.dictValue }}</Radio
            >
          </RadioGroup>
        </FormItem>
        <FormItem label="养老意愿" style="width: 100%">
          <RadioGroup v-model="formData.elderlyWill">
            <Radio label="1">由家人照顾养老</Radio>
            <Radio label="2">社区居家养老</Radio>
            <Radio label="3">机构养老</Radio>
          </RadioGroup>
        </FormItem>
      </Form>
    </Card>
    <Card>
      <p slot="title">养老服务建议</p>
      <Form :model="formData" label-colon :label-width="100">
        <FormItem label="服务类型">
          <RadioGroup v-model="formData.serviceType">
            <Radio label="1">由家人照顾养老</Radio>
            <Radio label="2">社区居家养老</Radio>
            <Radio label="3">机构养老</Radio>
          </RadioGroup>
        </FormItem>
        <FormItem label="服务内容">
          <FormItem label="(一)直接生活照顾" :label-width="130">
            <CheckboxGroup v-model="formData.serviceItemCreateRoList">
              <Checkbox
                :label="item.itemCode"
                v-for="(item, index) in serviceList1"
                :key="index"
                >{{ item.itemName }}</Checkbox
              >
            </CheckboxGroup>
          </FormItem>
          <FormItem label="(二)间接生活照顾" :label-width="130">
            <CheckboxGroup v-model="formData.serviceItemCreateRoList">
              <Checkbox
                :label="item.itemCode"
                v-for="(item, index) in serviceList2"
                :key="index"
                >{{ item.itemName }}</Checkbox
              >
            </CheckboxGroup>
          </FormItem>
          <FormItem label="(三)精神心理照顾" :label-width="130">
            <CheckboxGroup v-model="formData.serviceItemCreateRoList">
              <Checkbox
                :label="item.itemCode"
                v-for="(item, index) in serviceList3"
                :key="index"
                >{{ item.itemName }}</Checkbox
              >
            </CheckboxGroup>
          </FormItem>
          <FormItem label="(四)机能训练照顾" :label-width="130">
            <CheckboxGroup v-model="formData.serviceItemCreateRoList">
              <Checkbox
                :label="item.itemCode"
                v-for="(item, index) in serviceList4"
                :key="index"
                >{{ item.itemName }}</Checkbox
              >
            </CheckboxGroup>
          </FormItem>
          <FormItem label="(五)医疗护理照顾" :label-width="130">
            <CheckboxGroup v-model="formData.serviceItemCreateRoList">
              <Checkbox
                :label="item.itemCode"
                v-for="(item, index) in serviceList5"
                :key="index"
                >{{ item.itemName }}</Checkbox
              >
            </CheckboxGroup>
          </FormItem>
        </FormItem>
        <FormItem label="其他">
          <Input v-model.trim="formData.serviceOther"></Input>
        </FormItem>
        <div style="color: #ff3300; margin-left: 20px">
          备注:评估对象有疑似口精神病口传染性疾病的·应由相应专业机构提供服务
        </div>
      </Form>
    </Card>
    <Card>
      <Form
        :model="formData"
        label-colon
        :label-width="100"
        style="margin: 0 20px"
      >
        <FormItem label="参考照顾时间" style="width: 100%">
          <InputNumber
            :max="144"
            :min="0"
            v-model="formData.careTime"
            style="width: 200px"
          ></InputNumber>
          (分钟/天)
        </FormItem>
        <FormItem label="特殊情况描述" style="width: 100%">
          <Input v-model.trim="formData.specialDesc" />
        </FormItem>
        <FormItem label="评估员确定" style="width: 100%">
          <div
            v-for="(item, index) in formData.resultAssessorRoList"
            :key="index"
          >
            <FormItem
              :label="'评估人' + (index + 1)"
              style="width: 300px; display: inline-block"
              :label-width="70"
            >
              <Input v-model.trim="item.assessor" style="width: 200px"></Input>
            </FormItem>
            <FormItem
              label="评估时间"
              style="width: 300px; display: inline-block"
            >
              <DatePicker
                type="date"
                style="width: 200px"
                v-model="item.assessDate"
              ></DatePicker>
            </FormItem>
            <Button
              type="primary"
              style="margin-left: 20px"
              v-if="index == 0"
              @click="addResultAssessor"
              ><Icon type="ios-add-circle-outline"
            /></Button>
            <Button
              type="error"
              style="margin-left: 20px"
              v-else
              @click="delResultAssessor(index)"
              ><Icon type="ios-trash-outline"
            /></Button>
            <div style="margin-bottom: 20px"></div>
          </div>
        </FormItem>
        <FormItem label="评估机构确定">
          <Input
            v-model.trim="formData.orgConfirm"
            style="width: 200px"
          ></Input>
          <FormItem
            label="确定时间"
            style="width: 300px; display: inline-block"
          >
            <DatePicker
              type="date"
              style="width: 200px"
              v-model="formData.confirmDate"
            ></DatePicker>
          </FormItem>
        </FormItem>
        <div style="color: #ff3300; margin-left: 20px">
          注1:评估对象及其法定监护人对评估结论有异议时,可以提出复核评估申请。
        </div>
        <div style="color: #ff3300; margin-left: 20px">
          注2:评估对象身体如有特殊情况’如失聪丶失明丶失能丶需要辅助器貝等可在“特殊情况描迒″栏中说眀。
        </div>
      </Form>
    </Card>
    <Card style="margin-top: 20px">
      <p slot="title">
        可享福利<span style="color: #aaa"
          >（可多选，具体的福利需要单独申请）</span
        >
      </p>
      <Form
        :model="formData"
        inline
        label-colon
        :label-width="100"
        class="flexForm"
      >
        <CheckboxGroup v-model="formData.assessGuideItemCreateRoList">
          <Checkbox
            v-for="(item, index) in assessGuideList"
            :key="index"
            :label="item.guideItemId"
            style="display: block; margin: 0 0 10px 50px"
            >{{ item.name }}</Checkbox
          >
        </CheckboxGroup>
      </Form>
    </Card>
    <div style="margin: 10px 20px 20px">
      <Progress
        v-if="file.showProgress"
        :percent="file.percentage"
        :stroke-width="5"
      ></Progress>
      上传文件：
      <Upload
        :before-upload="beforeUpload"
        style="display: inline-block"
        :on-progress="fileProgress"
        :on-success="successFile"
        :action="fileAction"
        :data="uploadData"
        :show-upload-list="false"
      >
        <Button icon="ios-cloud-upload-outline">上传评定报告</Button>
      </Upload>
      <div>已上传文件：{{ showFileName }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["infoId","addChangeIden"],
  data() {
    const validateMobile = (rule, value, callback) => {
      if (value && value.length != 11) {
        callback("手机号格式不正确");
      } else {
        callback();
      }
    };
    return {
      formData: {
        careTime: 0,
        assessGuideItemCreateRoList: [],
        serviceItemCreateRoList: [],
        resultAssessorRoList: [{ assessDate: "", assessor: "" }],
      },
      validateForm: {
        phone: [{ validator: validateMobile, trigger: "blur" }],
      },
      //   区，街道，社区列表
      communityList: [],
      // 老年人
      elderlyAbilityList: [],
      // 医疗照护
      medicalCareList: [],
      // 疾病状况
      diseaseStatusList: [],
      //   老人照顾需求
      elderlyCareLevelList: [],
      // 服务内容
      serviceList1: [],
      serviceList2: [],
      serviceList3: [],
      serviceList4: [],
      serviceList5: [],
      // 可享福利
      assessGuideList: [],
      //上传
      fileAction: "",
      file: "",
      fileName: "",
      filePath: "",
      uploadData: {},
      assessResultFileRo: {},
      fileSize: "",
      showFileName: ''
    };
  },
  methods: {
    // 通过手机号查询信息
    searchInfo(val) {
      if (val) {
        this.$refs.phoneForm.validate((status) => {
          if (status) {
            this.$get(
              "/pension/api/pc/elderly/assessRecord/getAssessResultUser",
              {
                mobile: val,
              }
            ).then((res) => {
              if (res.code == 200 && res.data) {
                this.$Message.success({
                  background:true,
                  content: "查询完成，已为您自动填写基本信息"
                })
                this.formData.birthday = new Date(res.data.birthday);
                this.formData.idNum = res.data.idNum;
                this.formData.name = res.data.name;
                this.formData.sex = res.data.sex;
                 this.formData.userOrgCode = res.data.orgCode
              ? (() => {
                  let arr = res.data.orgCode.split("-");
                  let result = [];
                  arr.reduce(
                    (pre, cur, index) => {
                      if (index == 0) {
                      } else if (index > 1) {
                        pre += "-" + cur;
                        result.push(pre);
                      } else {
                        pre += "-" + cur;
                      }
                      return pre;
                    },
                    [arr[0]]
                  );
                  return result;
                })()
              : [];
                if(this.formData.idNum) {
                  this.formData.age = this.getAnalysisIdCard(this.formData.idNum,2);
                }
              } else if (res.code == 200 && !res.data) {
                this.$Message.info({
                  background: true,
                  duration: 2,
                  content: "暂未查询到相关信息，请您填写资料并绑定手机号",
                });
              } else {
                this.$Message.error({
                  background: true,
                  content: res.desc,
                });
              }
            });
          }
        });
      } else {
        this.$Message.error({
          background: true,
          content: "请输入手机号",
        });
      }
    },
    // 添加/删除评估员
    addResultAssessor() {
      this.formData.resultAssessorRoList.push({
        assessor: "",
        assessDate: "",
      });
    },
    delResultAssessor(index) {
      this.formData.resultAssessorRoList.splice(index, 1);
    },
    save() {
      if(!this.formData.name) {
        this.$Message.error({
          background: true,
          content: "请填写姓名"
        });
        return;
      }
      if(!this.formData.idNum) {
        this.$Message.error({
          background: true,
          content: "请填写身份证号"
        });
        return;
      }
      if(!this.formData.assessDate) {
        this.$Message.error({
          background: true,
          content: "请选择评估时间"
        });
        return;
      }
      let regFormat = /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/; //正确身份证
      if(this.formData.idNum && !regFormat.test(this.formData.idNum)) {
        this.$Message.error({
          background: true,
          content: "请填写正确的身份证号"
        });
        return;
      }
      this.$Message.loading({
        content: "正在保存，请稍等...",
        duration: 0,
      });
      // 保存参数
      let userOrgCodeArr = this.formData.userOrgCode && this.formData.userOrgCode.length > 0
        ? this.formData.userOrgCode[this.formData.userOrgCode.length - 1].split(
            "-"
          )
        : "";
      let userOrgCode = userOrgCodeArr[userOrgCodeArr.length - 1];
      let params = {
        ...this.formData,
        assessDate: this.formData.assessDate
          ? this.$core.formatDate(
              new Date(this.formData.assessDate),
              "yyyy-MM-dd"
            )
          : "",
        assessDateLast: this.formData.assessDateLast
          ? this.$core.formatDate(
              new Date(this.formData.assessDateLast),
              "yyyy-MM-dd"
            )
          : "",
        birthday: this.formData.birthday
          ? this.$core.formatDate(
              new Date(this.formData.birthday),
              "yyyy-MM-dd"
            )
          : "",
        confirmDate: this.formData.confirmDate
          ? this.$core.formatDate(
              new Date(this.formData.confirmDate),
              "yyyy-MM-dd"
            )
          : "",
        resultAssessorRoList: (() => {
          let arr = this.formData.resultAssessorRoList.map((item) => {
            return {
              assessor: item.assessor,
              assessDate: item.assessDate ? this.$core.formatDate(
                new Date(item.assessDate),
                "yyyy-MM-dd"
              ) : '',
            };
          });
          return arr
        })(),
        assessGuideItemCreateRoList: (() => {
          let arr = this.formData.assessGuideItemCreateRoList.map((item) => {
            return {
              guideItemId: item,
            };
          });
          return arr;
        })(),
        serviceItemCreateRoList: (() => {
          let arr = this.formData.serviceItemCreateRoList.map((item) => {
            return {
              itemCode: item,
            };
          });
          return arr;
        })(),
        userOrgCode: userOrgCode,
        assessResultFileRo: this.assessResultFileRo,
        oemCode: parent.vue.oemInfo.oemCode,
        orgCode: parent.vue.loginInfo.userinfo.orgCode,
      };
      let url = '';
      if(this.addChangeIden) {
        url = '/pension/api/pc/elderly/assessRecord/insertRecord';
      }else {
        url = '/pension/api/pc/elderly/assessRecord/updateAssessResult';
        params.resultId = this.infoId;
      }
      this.$post(url, params, {
        "Content-Type": "application/json",
      })
        .then((res) => {
          this.$Message.destroy();
          if (res.code == 200) {
            this.$Message.success({
              background: true,
              content: "保存成功",
            });
            this.$emit("saveSuccess");
          } else {
            this.$Message.destroy();
            this.$Message.error({
              background: true,
              content: res.desc,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          this.$Message.destroy();
          this.$Message.error({
            background: true,
            content: "保存失败，请联系管理员处理",
          });
        });
    },
    changeIdNum(e) {
      let regFormat = /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
      if (regFormat.test(e.target.value)) {
        this.formData.birthday = this.getAnalysisIdCard(e.target.value, 1);
        this.formData.age = this.getAnalysisIdCard(e.target.value, 2);
      }
    },
    // 根据身份证算年龄和出生日期
    getAnalysisIdCard(card, num) {
      if (num == 1) {
        //获取出生日期
        let birth =
          card.substring(6, 10) +
          "-" +
          card.substring(10, 12) +
          "-" +
          card.substring(12, 14);
        return birth;
      }
      if (num == 2) {
        //获取年龄

        var myDate = new Date();

        var month = myDate.getMonth() + 1;

        var day = myDate.getDate();

        var age = myDate.getFullYear() - card.substring(6, 10) - 1;

        if (
          card.substring(10, 12) < month ||
          (card.substring(10, 12) == month && card.substring(12, 14) <= day)
        ) {
          age++;
        }

        return age;
      }
    },
    // 动态加载街道/社区
    loadCommunity(item, callback) {
      item.loading = true;
      let arrCode = item.value.split("-");
      this.$get("/old/api/pc/staff/selectCascadeDataScope", {
        orgCode: arrCode[arrCode.length - 1],
        oemCode: parent.vue.oemInfo.oemCode,
        orgCodeSelf: parent.vue.loginInfo.userinfo.orgCode,
        custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
      }).then((res) => {
        if (res.code == 200) {
          if (!item.index) {
            item.children = res.dataList.map((item) => {
              return {
                value: item.orgPath,
                label: item.orgName,
                orgLevel: item.orgLevel,
                loading: false,
                children: [],
                index: 1,
              };
            });
          } else {
            item.children = res.dataList.map((item) => {
              return {
                value: item.orgPath,
                label: item.orgName,
                orgLevel: item.orgLevel,
                index: 1,
              };
            });
          }
          item.loading = false;
          callback();
        }
      });
    },
    // 获取区
    getPulishData(code) {
      this.$get("/old/api/pc/staff/selectCascadeDataScope", {
        orgCode: code,
        oemCode: parent.vue.oemInfo.oemCode,
        orgCodeSelf: parent.vue.loginInfo.userinfo.orgCode,
        custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
      }).then((res) => {
        if (res.code == 200) {
          this.communityList = res.dataList.map((item) => {
            return {
              value: item.orgPath,
              label: item.orgName,
              orgLevel: item.orgLevel,
              loading: false,
              children: [],
            };
          });
        }
      });
    },
    // 上传文件
    beforeUpload(file) {
      // 根据索引值，把需要上传的file添加到对应的table3项中
      let suffix = file.name.substr(file.name.lastIndexOf("."));
      if (suffix != ".pdf" && suffix != ".jpg" && suffix != ".png") {
        this.$Notice.warning({
          title: "上传文件格式错误！",
          desc:
            "文件 " + file.name + " 上传失败, 请上传pdf,jpg,png格式的文件！",
        });
        return false;
      } else if (file.size / 1024 / 1024 > 5) {
        this.$Notice.error({
          title: "上传失败",
          desc: "文件 " + file.name + " 大小已超出限制，请控制在5M以内",
        });
        return false;
      }
      let url =
        window.vue.getProxy()["/pension"].target +
        "/api/common/oss/getWebPolicy";
      return this.$get(url).then((res) => {
        if (res.code == 200) {
          let now = this.$core.randomString(9);
          this.uploadData = {
            name: now + file.name,
            host: res.data.host,
            key: res.data.key + now + file.name,
            policy: res.data.encodedPolicy,
            OSSAccessKeyId: res.data.accessId,
            success_action_status: "200",
            signature: res.data.postSignature,
          };
          this.fileAction = res.data.host;
          this.fileName = this.uploadData.name;
          this.filePath = this.fileAction + this.uploadData.key;
          this.fileSize = file.size;
        }
      });
    },
    fileProgress(event, file, fileList) {
      this.file = file;
      event.target.onprogress = (event) => {
        let uploadPercent = parseFloat(
          ((event.loaded / event.total) * 100).toFixed(2)
        ); // 保留两位小数，具体根据自己需求做更改
        // 手动设置显示上传进度条 以及上传百分比
        this.file.showProgress = true;
        this.file.percentage = uploadPercent;
      };
    },
    // 文件上传成功
    successFile() {
      this.assessResultFileRo = {
        fileName: this.fileName,
        filePath: this.filePath,
        fileSize: this.fileSize,
      };
      this.showFileName = this.fileName.substring(9);
      this.$Notice.success({
        title: "上传成功",
        desc: "文件 " + this.fileName + " 已成功上传",
      });
    },
  },
  created() {
    // 获取广州市的区
    this.getPulishData(4401);
    // 获取老年人能力
    this.$get("/pension/api/pc/elderly/assessRecord/getElderlyAbility").then(
      (res) => {
        if (res.code == 200 && res.dataList) {
          this.elderlyAbilityList = res.dataList;
        } else {
          this.$Message.error({
            background: true,
            content: res.desc,
          });
        }
      }
    );
    // 获取医疗照护
    this.$get("/pension/api/pc/elderly/assessRecord/getMedicalCare").then(
      (res) => {
        if (res.code == 200 && res.dataList) {
          this.medicalCareList = res.dataList;
        } else {
          this.$Message.error({
            background: true,
            content: res.desc,
          });
        }
      }
    );
    // 获取疾病状况
    this.$get("/pension/api/pc/elderly/assessRecord/getDiseaseStatus").then(
      (res) => {
        if (res.code == 200 && res.dataList) {
          this.diseaseStatusList = res.dataList;
        } else {
          this.$Message.error({
            background: true,
            content: res.desc,
          });
        }
      }
    );
    // 获取老人照顾需求
    this.$get("/pension/api/pc/elderly/assessRecord/getElderlyCareLevel").then(
      (res) => {
        if (res.code == 200 && res.dataList) {
          this.elderlyCareLevelList = res.dataList;
        } else {
          this.$Message.error({
            background: true,
            content: res.desc,
          });
        }
      }
    );
    // 获取服务内容
    this.$get("/pension/api/pc/elderly/itemConfig/queryConfigByOemCode", {
      oemCode: parent.vue.oemInfo.oemCode,
    }).then((res) => {
      if (res.code == 200 && res.dataList) {
        res.dataList.map((item) => {
          if (item.itemType == "1") {
            this.serviceList1.push({
              itemCode: item.itemCode,
              itemName: item.itemName,
            });
          } else if (item.itemType == "2") {
            this.serviceList2.push({
              itemCode: item.itemCode,
              itemName: item.itemName,
            });
          } else if (item.itemType == "3") {
            this.serviceList3.push({
              itemCode: item.itemCode,
              itemName: item.itemName,
            });
          } else if (item.itemType == "4") {
            this.serviceList4.push({
              itemCode: item.itemCode,
              itemName: item.itemName,
            });
          } else if (item.itemType == "5") {
            this.serviceList5.push({
              itemCode: item.itemCode,
              itemName: item.itemName,
            });
          }
        });
      } else {
        this.$Message.error({
          background: true,
          content: res.desc,
        });
      }
    });
    // 可享福利 assessGuideList
    this.$get("/pension/api/pc/elderly/guideItem/queryList").then((res) => {
      if (res.code == 200 && res.dataList) {
        this.assessGuideList = res.dataList.map((item) => {
          return {
            guideItemId: item.guideItemId,
            name: item.name,
          };
        });
      } else {
        this.$Message.error({
          background: true,
          content: res.desc,
        });
      }
    });
    // 修改获取数据
    if (this.infoId) {
      this.$get(
        "/pension/api/pc/elderly/assessRecord/getResultDetailByResultId",
        {
          resultId: this.infoId,
        }
      )
        .then((res) => {
          if (res.code == 200 && res.data) {
            this.showFileName = res.data.assessResultFileVo&&res.data.assessResultFileVo.fileName ? res.data.assessResultFileVo.fileName.substring(9) : '';
            this.formData = {
              name: res.data.userElderlyDetailVo
                ? res.data.userElderlyDetailVo.name
                : "",
              sex: res.data.userElderlyDetailVo
                ? res.data.userElderlyDetailVo.sex
                : "",
              idNum: res.data.userElderlyDetailVo
                ? res.data.userElderlyDetailVo.idNum
                : "",
              birthday: res.data.userElderlyDetailVo ? new Date(res.data.userElderlyDetailVo.birthday) : '',
              assessType: res.data.assessType,
              phone: res.data.phone,
              assessDate: res.data.assessDate ? new Date(res.data.assessDate) : '',
              elderlyAbility: res.data.elderlyAbility,
              medicalCare: res.data.medicalCare,
              diseaseStatus: res.data.diseaseStatus,
              supportLevel: res.data.supportLevel,
              elderlyCareLevel: res.data.elderlyCareLevel,
              elderlyWill: res.data.elderlyWill,
              assessDateLast: res.data.assessDateLast ? new Date(res.data.assessDateLast) : '',
              serviceType: res.data.serviceType,
              orgConfirm: res.data.orgConfirm,
              confirmDate: res.data.confirmDate ? new Date(res.data.confirmDate) : '',
              serviceItemCreateRoList: res.data.serviceItemVoList
                ? (() => {
                    let arr = [];
                    res.data.serviceItemVoList.map((item) => {
                      arr.push(item.itemCode);
                    });
                    return arr;
                  })()
                : [],
              serviceOther: res.data.serviceOther,
              careTime: Number(res.data.careTime),
              specialDesc: res.data.specialDesc,
              resultAssessorRoList: res.data.resultAssessorVoList ? (() => {
                let arr = res.data.resultAssessorVoList.map(item => {
                  return {
                    assessor: item.assessor,
                    assessDate: item.assessDate ? new Date(item.assessDate) : ''
                  }
                });
                return arr;
              })() : [],
              assessGuideItemCreateRoList: res.data.assessGuideItemVoList
                ? (() => {
                    let arr = [];
                    res.data.assessGuideItemVoList.map((item) => {
                      arr.push(item.guideItemId);
                    });
                    return arr;
                  })()
                : [],
            };
            this.formData.age = this.formData.idNum
              ? this.getAnalysisIdCard(this.formData.idNum, 2)
              : "";
            this.assessResultFileRo = res.data.assessResultFileVo;
            this.formData.userOrgCode = res.data.userOrgCode
              ? (() => {
                  let arr = res.data.userOrgCode.split("-");
                  let result = [];
                  arr.reduce(
                    (pre, cur, index) => {
                      if (index == 0) {
                      } else if (index > 1) {
                        pre += "-" + cur;
                        result.push(pre);
                      } else {
                        pre += "-" + cur;
                      }
                      return pre;
                    },
                    [arr[0]]
                  );
                  return result;
                })()
              : [];

            this.$Message.destroy();
          } else {
            this.$Message.destroy();
            this.$Message.error({
              background: true,
              content: res.desc,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          this.$Message.error({
            background: true,
            content: "获取数据失败，请联系管理员处理",
          });
        });
    }
  },
};
</script>
    
<style scoped lang='less'>
.ivu-card {
  margin-bottom: 20px;
}
.flexForm {
  display: flex;
  flex-wrap: wrap;
  .ivu-form-item {
    width: 30%;
  }
  /deep/.ivu-input[disabled],
  fieldset[disabled] .ivu-input {
    background-color: #fff;
    color: #515a6e;
  }
}
/deep/.validate {
  &::before {
    content: "*";
    display: inline-block;
    margin-right: 4px;
    line-height: 1;
    font-family: SimSun;
    font-size: 14px;
    color: #ed4014;
  }
}
</style>